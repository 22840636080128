
@import url('https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&display=swap')
*
    box-sizing: border-box
body
    background-color: #aff
    font-family: "Reddit Mono", monospace
    font-optical-sizing: auto
    font-weight: 400
    font-style: normal
    text-align: center

header
    display: flex
    flex-direction: row
    justify-content: space-between
    align-content: center
    align-items: center
    max-width: 1200px
    margin: 0 auto
    padding: 1em
    position: relative
.faceHeader
    border-radius: 14em
    width: 6em
    border: .2em solid #faf
    animation: rotacion 10s linear infinite

.headerLinks
    display: flex
    flex-direction: row
    align-items: center
    a
        margin-left: 1em
        transform: scale(1.0)
        transition: all 40ms
        &:hover
            transform: scale(1.05)
.socialIcon
    background-color: black
    border-radius: 3em
    display: flex
    align-content: center
    justify-items: center
    flex-direction: row
    width: 50px
    height: 50px
    svg
        padding: .7em

@keyframes rotacion
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(359deg)

footer
    color: #faf
    margin-top: 5em
.headerTitleContainer
    position: absolute
    text-align: center
    width: 100%
    margin: 0px 0px
.headerTitle
    font-size: 4em
    display: inline-block
    color: #faf
    margin: 0
    text-shadow: 0 1px 1px black, 0 -1px 1px black, 1px 0px 1px black, -1px 0px 1px black
    cursor: pointer
    user-select: none
    transform: scale(1)
    transition: all 50ms
    &:hover
        transform: scale(1.1)

#copied
    display: block
    height: 0em
    line-height: 0em
    overflow: hidden
    transition: all 70ms
.copiedShow
    height: 1em!important
    line-height: 1em!important

@media (max-width:650px)
    header
        width: 100%
        max-width: 100%
        margin-bottom: 7em
    .headerTitleContainer
        top: 8em
        margin-left: -1em
    footer
        margin-top: 3em
