.charEditor
    border: 1px solid rgba(0,0,0,0.8)
    border-radius: 1em
    width: 500px
    height: 500px
    margin: 0 auto
    margin-top: 2em
    overflow: hidden
    margin-bottom: 1em
    &>div
        width: 100%
        height: 100%
        position: relative
        &>div
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            img
                width: 100%
                height: 100%

.editorButtons
    position: relative
.accButton
    top: 23%
    left: 35%
    position: absolute
    height: 30%
    width: 30%
.plButton
    top: 53%
    left: 35%
    position: absolute
    height: 30%
    width: 30%
.bgButton
    position: absolute
    height: 100%
    width: 100%
    top: 0
    left: 0

.containerEditor
    text-align: center
    width: 100vw

button
    font-size: 1.1em
    padding: .2em .6em

@media (max-width:430px)
    .charEditor
        width: 350px
        height: 350px
        margin: 0 auto
        margin-bottom: 1em
    .containerEditor
        font-size: .8em

@media (max-width:350px)
    .charEditor
        width: 300px
        height: 300px
        margin: 0 auto
        margin-bottom: 1em
